import React, { useState, useEffect } from "react";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from "@material-ui/core/Container";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import DetailPanel from 'components/DetailPanel/DetailPanel.js';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import MaterialTable from "material-table";
import { useConfirm } from "material-ui-confirm";

// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import cursorIcon from "assets/icon/cursor-double-click.png";
import usersIcon from "assets/icon/single-neutral.png";

import copyIcon from "assets/icon/copy-icon.png";
import editIcon from "assets/icon/edit-icon.png";
import delIcon from "assets/icon/del-icon.png";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {iconActiveClass, iconImage} from "utils/helpers";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import linksService from 'services/links.js';


export default function MyLinks() {
const history = useHistory();

  var copy=false;
  const [myLinks, setMyLinks] = useState([]);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    copy=true;
  };

  const handleClose = () => {
    // setAnchorEl(null);
    
  };
  
  const copyGeneratedLink = async (event) => {
    NotificationManager.success('Your Link Is Copied To Clipboard');
  }



  function chngHandler(){

  };

  const confirm = useConfirm();
  useEffect(() => {
    async function fetchLinks() {
      const myLinks = await linksService.getMyLinks();
      setMyLinks(myLinks);
    }
    fetchLinks();
    return () => {

    };
  }, []);


  const handleDelete = async (event, rowData) => {
      await confirm({ description: `This will permanently delete ${rowData.deepLink}.` })
      setMyLinks(myLinks.filter(link => link.id !== rowData.id));
      await linksService.remove(rowData.id);
      NotificationManager.success('Success: The Deep Link is Deleted!..');
  };

  const ReturnMenu = ({data}) => {
    
    return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <IconButton color="dark" {...bindTrigger(popupState)}>
            <MoreVertIcon />
          </IconButton>
          <Popover className="tr-menu"
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
              <MenuItem><img src={copyIcon} alt="icon"/>
               <CopyToClipboard text={data.originalLink}>
                  <span onClick={copyGeneratedLink}>Copy Link</span>
              </CopyToClipboard>
                </MenuItem>
              <MenuItem onClick={() => {
                  history.push('/edit-link'+'/'+data.id);
                }}> <img src={editIcon} alt="icon"/>Edit</MenuItem>
              <MenuItem onClick={e => handleDelete(e,data)}> <img src={delIcon} alt="icon"/>Delete</MenuItem>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
  };
  return (
    <Container>
    <GridContainer>
      <h1>Your links</h1>
      <GridItem xs={12} sm={12} md={12}>
            
            <MaterialTable
              components={{
                Container: ({children, ...props}) => <div {...props} className="cus-table"><div className="cus-tabl-body">{children}</div></div>,
                Action: props => (
                  <div className="menuWrap">
                    <ReturnMenu {...props} />
                  </div>
                ),
              }}
              columns={[
                { title: 'Deep Link', field: 'deepLink', render: ({deepLink, originalLink}) => <a href={`http://deepy.io/${deepLink}`}><span className={"icon-Wrap " + iconActiveClass(originalLink)}><img src={iconImage(originalLink)} alt="icon"/></span> {deepLink}</a> },
                { title: 'Original Link', field: 'originalLink', render: ({originalLink}) => originalLink.replace(/^www\./, '') }, 

                { title: 'Clicks', field: 'clicks', render: ({clicks}) => <div className="clicksWrap">
                  <img src={cursorIcon} alt="icon"/>
                  {clicks} clicks</div> },

                { title: 'Users', field: 'uniqueVisitors', render: ({uniqueVisitors}) => <div className="clicksWrap">
                <img src={usersIcon} alt="icon"/>
                {uniqueVisitors} users</div> },                  

                ]}
              data={myLinks}
              detailPanel={rowData => <DetailPanel linkData={rowData} />}
              options={{
                pageSize: 10,
                search: true,
                showTitle: false,
                filtering: false,
                exportButton: false,
                draggable: false,
                actionsColumnIndex: -1
              }}
                actions={[
                {
                  icon: MoreVertIcon,
                  tooltip: 'Delete Link',
                  onClick: handleClick
                }
              ]}
              
            />
      </GridItem>
    </GridContainer>
    <NotificationContainer/>
    </Container>
  );
}