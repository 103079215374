import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Material Icons
// @material-ui/icons
import {
  Link as LinkIcon,
  Timeline as TimelineIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(8,0,6)
  },
  title: {
    fontWeight: 700,
    lineHeight: '1.125',
    fontSize: '1.8rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
  },
  itemTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  subtitle: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  ctaDiv: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0),
  },
  icon: {
    fontSize: 64,
    color: '#fff'
  },

  bodyText: {
    textAlign: 'center'
  }
}));

export default function LanderFeatures(props) {
  const classes = useStyles();

  return (
    <Container className={classes.container} id='features'>
      {/* Increase the network loading priority of the background image. */}
      <Typography align="center" variant="h2" color="textPrimary" className={classes.title}>
        What Does Deepy Do?
      </Typography>
      <Typography align="center" variant="h5" className={classes.subtitle} color="textSecondary">
        The all-in-one marketing tool that allows you to shorten, track and create deep links to convert your clicks to customers.
      </Typography>
       <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <div className={classes.item}>
              <LinkIcon  className={classes.icon}/>
              <Typography variant="h6" className={classes.itemTitle} color="textPrimary">
                Shorten Links
              </Typography>
              <Typography variant="body1" className={classes.bodyText} color="textSecondary">
                Tired of long, ugly URLs? Use deepy.io to create shortened, branded links that establish more trust and drive more clicks!
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.item}>
              <TimelineIcon  className={classes.icon}/>
              <Typography variant="h6" className={classes.itemTitle} color="textPrimary">
                Track Clicks
              </Typography>
              <Typography variant="body1" className={classes.bodyText} color="textSecondary">
                Leverage detailed analytics, including traffic volume, location data, device and browser preferences, and website referrer data to better segment and understand your audience!
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.item}>
              <ExitToAppIcon className={classes.icon}/>
              <Typography variant="h6" className={classes.itemTitle} color="textPrimary">
                Create Deep Links
              </Typography>
              <Typography variant="body1" className={classes.bodyText} color="textSecondary">
                Avoid logins and lost conversions by directly linking in-app to your app or social profiles. Convert more leads to customers, followers, and subscribers!
              </Typography>
            </div>
          </Grid>
        </Grid>
    </Container>
  );
};