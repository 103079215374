import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  fade,
  withStyles,
} from '@material-ui/core/styles';

import validateURL  from "utils/helpers";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import Button from 'components/CustomButtons/Button.js';
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import linksService from 'services/links.js';

import newLinkIcon from "assets/icon/link-icon.svg";
import genratedlink from "assets/icon/genratedlink-icon.png";
import facebookIcon from "assets/icon/social/facebook.png";
import instagramIcon from "assets/icon/social/instagram.png";
import messengerIcon from "assets/icon/social/messenger.png";
import questionIcon from "assets/icon/social/question.png";
import snapchatIcon from "assets/icon/social/snapchat.png";
import telegramIcon from "assets/icon/social/telegram.png";
import twitterIcon from "assets/icon/social/twitter.png";
import youtubeIcon from "assets/icon/social/youtube.png";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import fileIcon from "assets/icon/social/file-double.png";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import 'react-notifications/dist/react-notifications.js';

// import {TextField} from "@material-ui/core";

import {
  makeStyles
} from "@material-ui/core/styles";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardIconWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 15,
    minHeight: 48,
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  Button: {
    fontSize: 15,
  },
}))(InputBase);

const CreatorDialog = (props) => {

  const classes = makeStyles(styles)();
  const [link, setLink] = useState('');
  const [suffix, setSuffix] = useState('');
  const [deepLink, setDeepLink] = useState('');
  const [urlType, setUrlType] = useState('');
  const history = useHistory();

  const copyGeneratedLink = async (event) => {
    NotificationManager.success('Your Link Is Copied To Clipboard');
  }
  const generateDeepLink = async (event) => {
    event.preventDefault();
      if (link) {
                   
          try {
                 const savedLink = await linksService.create({ link, suffix: suffix || null });
                if (savedLink.error !== undefined) {
                  NotificationManager.warning('Error: Suffix Already Defined!');
                  setDeepLink('Error: Suffix Already Defined!');
                } else {
                  setDeepLink(`https://deepy.io/${savedLink.deepLink}`);
                  NotificationManager.success('Success: Generated The New Deep Link');

                }
         } catch (err) {
         // handle the error safely
           NotificationManager.warning('Please enter correct deepLink...! ');
        }
                        
    } else {
      NotificationManager.warning('Error: Link is Required');
      setDeepLink('Error: Link is Required');
    }
  };

  const handleLinkChange = val => {
    let activeLink = validateURL(val);
    setUrlType(activeLink);
    setLink(val);
  }

  return (
    <Container className={classes.container}>
      <div className="max-width-515">
       <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h1>Create Link</h1>
          </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { (deepLink === "") ? 
          <Card>
            <CardBody>

              <FormControl className="form-control">
                <InputLabel shrink htmlFor="link-input" className={classes.Label}>
                  Link to convert
                </InputLabel>
                <BootstrapInput  id="link-input" value={link} onChange={e=> handleLinkChange(e.target.value) } />
                
              </FormControl>

              <FormControl className="form-control">
                <InputLabel shrink htmlFor="suffix-input">
                Link name (optional) 
                </InputLabel>
                <BootstrapInput  id="suffix-input" value={suffix} onChange={e=>setSuffix(e.target.value)}/>
                <span className="input-tag">deepy.io/</span>
              </FormControl>

              <FormControl className="form-control">
                <InputLabel shrink htmlFor="suffix-input">
                Source type:
                </InputLabel>
                <ul className="SocialButton">

                    <li className={"quIcon " + ((urlType == 'qa') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={questionIcon} alt="icon" /></a>
                    </li>
                    <li className={"fbIcon " + ((urlType == 'facebook') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={facebookIcon} alt="icon" /></a>
                    </li>
                    <li className={"mesIcon " + ((urlType == 'whatsapp') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={messengerIcon} alt="icon" /></a>
                    </li>
                    <li className={"instaIcon " + ((urlType == 'instagram') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={instagramIcon} alt="icon" /></a>
                    </li>
                    <li className={"ytIcon " + ((urlType == 'youtube') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={youtubeIcon} alt="icon" /></a>
                    </li>
                    <li className={"snapcIcon " + ((urlType == 'snapchat') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={snapchatIcon} alt="icon" /></a>
                    </li>
                    <li className={"telegIcon " + ((urlType == 'telegram') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={telegramIcon} alt="icon" /></a>
                    </li>
                    <li className={"twIcon " + ((urlType == 'twitter') ? 'selected' : '')}>
                      <a href="#" onClick={e => e.preventDefault()}><img src={twitterIcon} alt="icon" /></a>
                    </li>
                </ul>
              </FormControl>

              <Button variant='contained' color='warning' onClick={generateDeepLink} className="button-icon"><img src={newLinkIcon} alt="icon" />Create Link</Button>
            </CardBody>
          </Card>
          :
          <Card className="generated-link">
            <CardHeader color="primary">
            <img src={genratedlink} alt="icon" />
            <h4>Generated Deep Link</h4>
            </CardHeader>
            <CardBody>
              <FormControl className="form-control">
                <BootstrapInput  id="link-input" value={deepLink} readOnly/>
                <CopyToClipboard text={deepLink}>
                <Button variant='contained' color='primary' className="link-copy" onClick={copyGeneratedLink} ><img src={fileIcon} alt="icon" />
                </Button>
                </CopyToClipboard>
              </FormControl>
              <div className="button-group">
                <Button variant='contained' color='warning' className="button-icon" onClick={() => {
                  history.push('/dashboard');
                }}>To dashboard</Button>
                <Button variant='outlined' color='warning' className="button-icon outline-button" onClick={e => {
                  setLink("");
                  setUrlType("");
                  setDeepLink("");
                }}>Make another link</Button>
              </div>
            </CardBody>
          </Card>
          }

        </GridItem>
      </GridContainer>
      </div>
      <NotificationContainer/>
    </Container>
  );

};

export default CreatorDialog;